
body, #stats-bar, .img-series, .img-series div, #arrow-area, #next-synset-area, #next-synsets, #next-syn-a-text, #next-syn-b-text {
  display: flex;
}


/* Body */
body {

  /* Minimum window height with vertical center. */
  min-height: 100dvh;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  margin: 0;
  padding: 3vw;
  font-family: 'roboto_light', sans-serif;
  text-align: center;
  background-color: hsl(0, 0%, 8%);
}
body.lose {
  background-color: hsl(0, 100%, 5%);
}
body.win {
  background-color: hsl(102, 100%, 5%);
}


/* Heart Pixelation */
.full-heart, .empty-heart {
  image-rendering: pixelated;
}


/* Synset Text Styling */
.words {
  font-family: Futura, "Century Gothic", sans-serif;
  font-size: 1.2rem;
}
.pointer, .gloss {
  font-size: 0.8rem;
}
.pointer, .pos {
  font-style: italic;
}
.pos {
  opacity: 50%;
}
.gloss {
  opacity: 70%;
}


/* Game Stats Bar */
#stats-bar {
  justify-content: space-between;
}
#stats-bar, .img-series {
  flex-wrap: wrap;
}
.img-series {
  align-items: center;
  margin-bottom: 0.6rem;
}
.img-series div {
  align-items: center;
  justify-content: center;
  height: 2rem;
  aspect-ratio: 7 / 6;  /* Match image. */
  margin-right: 0.2rem;
}
.img-series div img {
  height: 80%;
}


/* Heartbeat Animation */
body.win .full-heart.last img {
  animation-name: none;
}
.full-heart.last img {
  animation: 1s infinite normal heartbeat;
  animation-timing-function: linear;
}
@keyframes heartbeat {
  from {
    height: 100%;
  }
  to {
    height: 65%;
  }
}


/* Win/Lose Heading */
#lose-heading {
  color: hsl(0, 100%, 50%);
}
#win-heading {
  color: hsl(102, 100%, 50%);
}
#win-stats {
  display: block;
  font-size: small;
  font-weight: normal;
  color: hsl(102, 100%, 90%);
}


/* Previous Node Area */
#prev-synsets {
  margin: 0.7rem 0;  /* Match body.lose #target-margin height to #prev-synsets margin-bottom. */
  color: hsla(0, 0%, 100%, 70%);
}
#prev-synsets .pointer {
  font-size: 0.8rem;
  color: hsla(0, 0%, 100%, 50%);
}


/* Current/Target Node Area */
#curr-synset, #target {
  padding: 0.7rem;
  border: 5px solid hsl(313, 0%, 30%);
  color: white;
}
#curr-synset .endpoint-heading, #target .endpoint-heading {
  color: hsl(133, 25%, 50%);
}
#target {
  box-sizing: border-box;
}
#target-margin {
  height: 3rem;
}
body.lose #target-margin {
  height: 0.7rem;  /* Match body.lose #target-margin height to #prev-synsets margin-bottom. */
}


/* Next Node Area */
#next-synset-col {
  margin: 0 3vw;
}
#next-synsets {
  flex: 18;
  flex-direction: row-reverse;
  cursor: pointer;
}
#next-syn-b-gutter, #next-syn-a-gutter {
  flex: 1;
  display: none;
}
#next-synsets .words {
  font-weight: bold;
}
#next-synsets .pointer {
  color: hsl(313, 100%, 100%);
}
#next-syn-a-gutter, #next-syn-a-text {
  /* background-color: hsl(86, 100%, 9%); */  /* Controlled with javascript. */
  color: hsl(86, 100%, 50%);
}
#next-syn-b-gutter, #next-syn-b-text {
  /* background-color: hsl(180, 100%, 9%); */  /* Controlled with javascript. */
  color: hsl(180, 100%, 50%);
}
#next-syn-b-text:hover ~ #next-synsets {
  opacity: 20%;
}
#next-syn-a-text {
  text-align: left;
}
#next-syn-b-text {
  text-align: right;
  border-left: 5px solid hsl(133, 0%, 0%);
}
#next-synset-area {
  border-left: 5px solid hsl(133, 0%, 50%);
  border-right: 5px solid hsl(133, 0%, 50%);
}
#future-choice-area {
  border-left: 5px solid hsla(0, 0%, 0%, 0%);
  border-right: 5px solid hsla(0, 0%, 0%, 0%);
}
#next-syn-a-text, #next-syn-b-text {
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
  padding: 1.2rem;
}


/* Small screens. Vertically overlapping layout. */
@media (max-width: 40rem) {

  #next-synsets {
    flex-direction: column;
  }
  #next-syn-b-gutter, #next-syn-a-gutter {
    display: block;
  }
  #next-syn-a-text {
    border-top: 5px solid hsl(133, 0%, 0%);
    border-right: 5px solid hsl(133, 0%, 0%);
  }
  #next-syn-a-text, #next-syn-b-text {
    flex: 0 0 auto;
  }
  #next-syn-b-text {
    padding-right: 0;
  }
  #next-syn-a-text {
    padding-left: 0;
  }
}


/* Stripe Area */
#future-choice-area {
  background-color: hsl(133, 100%, 5%);
}
#future-choice-area .a, #future-choice-area .b {
  height: 0.35rem;
}
#future-choice-area :first-child {
  border-top: 2px solid hsl(180, 40%, 22%);
}
#future-choice-area .b {
  border-bottom: 2px solid hsl(180, 40%, 22%);
}
#future-choice-area .a {
  border-bottom: 2px solid hsl(86, 40%, 22%);
}


/* Reset Button */
.reset-button {
  margin: 1.5rem;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  background-color: white;
}
.reset-button:hover {
  background-color: hsl(133, 100%, 50%);
}
